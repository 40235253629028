
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;

  &.is-users {
    margin-bottom: 6rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    gap: 1rem;
  }
  .pagination {
    padding-bottom: 2rem;
  }

  .icon {
    width: 4rem;
    height: 2.2rem;
  }

  .toggle-div {
    display: inline-flex;
  }
}
.geotab-container {
  min-height: 75vh;
}
