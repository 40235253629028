
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: inline-flex;
  align-items: flex-start;

  // position: relative;
  width: 90%;
  gap: 1rem;
  .filter-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  .filters-dropdown {
    > button {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      flex-shrink: 0;
      position: relative;
    }
  }
}

@media all and (max-width: 640px) {
  .container {
    & > div {
      width: 100%;
    }
  }
}
