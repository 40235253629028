
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .pane-1 {
    padding-right: 1rem;
  }
  .pane-2 {
    padding-left: 2rem;
    overflow-y: auto !important;
  }
  .accordion-header {
    font-weight: bold;
    font-family: inherit;
    font-size: 1.33rem;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9d9d9d;
  }
  .action-btn {
    padding: 0 0 5px;
  }
  .pagination {
  }
}
